.react-datepicker__day--disabled {
    position: relative; 
    color: white !important;
    background-color: #ccc6c6 !important; 
    cursor: not-allowed; 
    opacity: 1;
    width:100%;
    height:100%;
    // font-weight: 800;
    // background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2050%2050%22%20fill%3D%22none%22%20stroke%3D%22red%22%20stroke-width%3D%225%22%3E%3Cline%20x1%3D%2210%22%20y1%3D%2210%22%20x2%3D%2240%22%20y2%3D%2240%22%2F%3E%3Cline%20x1%3D%2210%22%20y1%3D%2240%22%20x2%3D%2240%22%20y2%3D%2210%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    border-radius: 25px;
    // font-size: 20px;
  }
  
  .react-datepicker__day--disabled::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px; 
    color: red; 
    pointer-events: none; 
  }

  .blue-date {
    background-color: green !important;
    color: white !important;
    background-size: cover;
    border-radius: 25px;
    font-size: 20px;
  }
  
  /* CustomDatePicker.scss */
.start-date {
  background-color: #4caf50 !important; /* Start date color (green) */
  color: white !important;
}

.end-date {
  background-color: #ff9800 !important; /* End date color (orange) */
  color: white !important;
}


.blurred-date-picker {
  background-color: rgba(0, 0, 0, 0.1); /* Light faded background */
  filter: blur(2px); /* Add a blur effect */
  cursor: not-allowed; /* Show the cursor as not-allowed */
}

.custom-datepicker .ant-picker-input input::placeholder {
  color: var(--placeholder-color, #888); 
}

body.dark-mode .custom-datepicker .ant-picker-input input::placeholder {
  color: var(--placeholder-dark-color, #aaa); 
}

.my-custom-datepicker .rainbow-date-picker__day--disabled {
  position: relative; /* Ensure positioning for pseudo-element */
  color: red; /* Change text color for visibility */
}

.my-custom-datepicker .rainbow-date-picker__day--disabled::after {
  content: '❌'; /* Add a cross icon */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em; /* Adjust size as needed */
}

.lcvIHF:focus {
  background-color: inherit !important; 
  outline: none; 
  border: 1px solid inherit !important; 
}

.m_8fb7ebe7 {
  background-color: inherit !important;
  border: 1px solid rgb(80, 85, 95, 0.5) !important;
  font-weight: 500 !important;
  padding: 8px;
}

.dark .m_8fb7ebe7 {
  background-color: rgba(var(--c-neutral-900), var(--tw-bg-opacity)) !important;
  border: 1px solid rgba(80, 85, 95, 0.5) !important;
}

.m_88bacfd0 {
  font-weight: 500;
  padding-left:8px;
  font-size:14px;
  color:#52525B;

}
.dark .m_88bacfd0 {
  color:inherit !important;

}
.m_8fdc1311{
  margin-top:18px;
}

.m_6fa5e2aa{
  font-weight: 500;
  color:#52525B;
  padding-left:8px;
  
}

.dark .m_6fa5e2aa{
  color:inherit !important; 
}