.css-1ha1ds5-Input,
#react-select-3-input {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

#react-select-4-input {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.css-1ha1ds5-Input:focus,
.css-1ha1ds5-Input:focus-visible,
.css-1ha1ds5-Input:hover {
  box-shadow: none !important;
  border-color: transparent !important;
}
