.react-calendar-container {
  width: 100%;
  margin: 0 auto;
  font-family: "Arial", sans-serif;

  .react-calendar {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;

    // Navigation Styles
    &__navigation {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding: 10px 0;

      &__label {
        font-size: 22px !important;
      }

      &__label__labelText {
        padding: 0px 40px;
      }

      &__arrow {
        padding: 0px 40px 40px;
      }
    }

    // Tile Styles
    &__tile {
      font-size: 14px;
      padding: 10px;
      height: 70px !important;
      width: 70px !important;
      transition: all 0.3s ease-in-out;
      border: 1px solid rgb(144 149 137 / 16%);

      &:hover {
        background-color: #f0f8ff;
        cursor: pointer;
      }

      &--active {
        background-color: black !important;
        color: white !important;
      }

      &:disabled {
        background-color: #f5f5f5;
        color: #ccc;
        cursor: not-allowed;
      }
    }

    // Month View
    &__month-view {
      &__days {
        &__day {

          &--weekend {
            // color: #d32f2f;
          }

          &--neighboringMonth {
            color: #ccc;
          }
        }
      }

      &__weekdays {
        &__weekday {
          text-align: center;
          margin-bottom: 20px;

          &.title {
            text-decoration: none;
            background-color: red;
          }
        }
      }
    }
  }

  // Date Highlights
  .black-date {
    background-color: black !important;
    color: white !important;
  }

  .green-date {
    background-color: green !important;
    color: white !important;
    border: 1px solid rgb(144 149 137);
  }

  .red-date {
    background-color: green !important;
    color: white !important;
  }

  .start-date {
    background-color: green !important;
    color: white !important;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5) !important;
    border: 1px solid rgb(144 149 137);
  }

  .end-date {
    background-color: green !important;
    color: white !important;
    box-shadow: 0 0 5px rgba(255, 152, 0, 0.5) !important;
    border: 1px solid rgb(144 149 137);
  }
}

abbr[title] {
  text-decoration: none !important; // or auto if tha
  font-size: 18px;
  font-weight: bolder;
}