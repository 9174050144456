.react-country-phone-number-input-box {
    position: relative;
    .react-international-phone-country-selector {
        position: absolute;
        left: 8px;
        top: 4px;
        background: transparent;
        .react-international-phone-country-selector-button {
            border: none;
            background: transparent;
        }
    }
    .react-international-phone-input {
        padding-left: 60px;
        color: inherit;
        background-color: transparent;
    }
}