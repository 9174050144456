/* Apply custom scrollbar styles */
.custom-design::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.custom-design::-webkit-scrollbar-thumb {
  background-color: orange; /* Blue color for the scrollbar thumb */
  border-radius: 10px; /* Rounded edges */
  border: 2px solid #e2e8f0; /* Optional: Add border around the thumb */
}

.custom-design::-webkit-scrollbar-track {
  background: #f7fafc; /* Light gray background for the track */
  border-radius: 10px; /* Rounded edges */
}

.custom-design::-webkit-scrollbar-thumb:hover {
  background-color: orange; /* Darker blue when hovering */
}

.custom-design::-webkit-scrollbar-track:hover {
  background: #e2e8f0; /* Darker background for the track when hovering */
}
