/* Apply custom scrollbar styles */
.design::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .design::-webkit-scrollbar-thumb {
    background-color:#9ca3af; /* Blue color for the scrollbar thumb */ 
    border: 2px solid #f3f4f6; /* Optional: Add border around the thumb */
    border-radius:4px;
  }
  
  .design::-webkit-scrollbar-track {
    background: #f3f4f6; /* Light gray background for the track */
    width:6px;
  }
  
  .design::-webkit-scrollbar-thumb:hover {
    background-color: #9ca3af; /* Darker blue when hovering */
    border-radius: 4px;
  }
  
  .design::-webkit-scrollbar-track:hover {
    background: #f3f4f6; /* Darker background for the track when hovering */
  }
  